import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";
import {
	Button, Typography, TextField, IconButton, InputAdornment
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import Cancel from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { styled } from '@mui/material/styles';

import html2canvas from 'html2canvas';


const StyledGrid = styled(({ ...props }) => (
	<Grid item {...props} xs={1} md={1} lg={1.5} />
))(({ theme }) => ({
}));

const UnclikableButton = styled(Button)(({ theme }) => ({
	width: '100%',
	height: '100%',
	fontSize: '20px',
	"&.Mui-disabled.MuiButton-outlined": {
		color: theme.palette.primary.main,
		borderColor: theme.palette.primary.main,
		borderWidth: '2px',
	},
	"&.Mui-disabled.MuiButton-contained": {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
	}
}));

const NameContainer = styled(TextField)(({ theme }) => ({
	width: '100%',
	height: '100%',
	
	input: {
		color: theme.palette.secondary.main,
		fontSize: '20px',
	},
	"& .MuiInputBase-adornedStart": {
		paddingLeft: '5px',
	},
	"& .MuiInputBase-adornedEnd": {
		paddingRight: 0,
	}
}));

const ClickableButton = styled(Button)(({ theme }) => ({
	width: '100%',
	height: '100%',
	fontSize: '20px',
}));

const InputAdornmentStyled = styled(InputAdornment)(({ theme }) => ({
	'& p': {
		color: theme.palette.secondary.main
	}
}));

const startPersons = [{ name: "" }, { name: "" }, { name: "" }, { name: "" }]
const startItems = [{ name: "", price: "", notPay: [] }, { name: "", price: "", notPay: [] }, { name: "", price: "", notPay: [] },
{ name: "", price: "", notPay: [] }, { name: "", price: "", notPay: [] }]

const NAME_LENGTH = 1
const ITEM_LENGTH = 1.2

const Caculator = forwardRef((props, ref) => {
	const [persons, setPersons] = useState(startPersons);
	const [items, setItems] = useState(startItems);
	const [tax, setTax] = useState(0);

	const table = useRef(null);

	useImperativeHandle(ref, () => {
		return {
			refresh,
			screenshot,
		}
	}, [])


	// the part of generating values and total money
	const values = [];
	const total = Array(persons.length).fill(0);
	items.forEach((item) => {
		let row = [];
		let numbersPay = persons.length - item.notPay.length;
		for (let i = 0; i < persons.length; i++) {
			if (item.notPay.includes(i)) {
				row.push(0);
			} else {
				let priceToPay = parseFloat(item.price ? item.price : 0) / numbersPay;
				row.push(priceToPay);
				total[i] += priceToPay;
			}
		}
		values.push(row);
	})
	for (let i = 0; i < persons.length; i++) {
		total[i] = (1 + tax / 100) * total[i];
	}

	const addPerson = () => {
		let newPersons = [...persons]
		newPersons.push({ name: "", total: 0 })
		setPersons(newPersons);
	}
	const editPerson = (index, name) => {
		let newPersons = [...persons];
		newPersons[index].name = name;
		setPersons(newPersons);
	}
	const removePerson = (index) => {
		if (persons.length === 1) {
			alert("Cannot remove the last person");
			return;
		}
		let newPersons = [...persons];
		newPersons.splice(index, 1);
		// remove that person in the notpay item
		let newItems = [...items];
		newItems.forEach((item) => {
			item.notPay = item.notPay.filter((i) => i !== index).map((i) => i > index ? i - 1 : i);
		})
		setPersons(newPersons);
		setItems(newItems);
	}
	const addItem = () => {
		let newItems = [...items];
		newItems.push({ name: "", price: "", notPay: [] });
		setItems(newItems);
	}
	const editItem = (index, name, price) => {
		let newItems = [...items];
		if (name != null) {
			newItems[index].name = name;
		}
		if (price != null) {
			newItems[index].price = price;
		}
		setItems(newItems);
	}
	const removeItem = (index) => {
		let newItems = [...items];
		newItems.splice(index, 1);
		setItems(newItems);
	}
	const cancelItemForPerson = (pindex, index) => {
		let newItems = [...items];
		let included = newItems[index].notPay.includes(pindex);
		if (included) {
			newItems[index].notPay = newItems[index].notPay.filter(i => i !== pindex)
		} else {
			newItems[index].notPay.push(pindex)
		}
		setItems(newItems);
	}

	// tax handler
	const editTax = (tax) => {
		if (tax === "") {
			tax = 0;
		}
		setTax(tax);
	}

	const round = (num) => {
		if (!num) return 0;
		return Math.round((num + Number.EPSILON) * 100) / 100;
	}

	// button click
	const clickAddPerson = () => {
		return () => addPerson();
	}
	const clickRemovePerson = (index) => {
		return () => removePerson(index);
	}
	const modifyPersonName = (index) => {
		return (e => editPerson(index, e.target.value));
	}
	const clickAddItem = () => {
		return () => addItem();
	}
	const clickRemoveItem = (index) => {
		return () => removeItem(index);
	}
	const modifyItemName = (index) => {
		return (e => editItem(index, e.target.value));
	}
	const modifyItemPrice = (index) => {
		return (e => editItem(index, null, e.target.value));
	}
	const clickPrice = (pindex, index) => {
		return () => cancelItemForPerson(pindex, index);
	}
	const modifyTax = () => {
		return (e => editTax(e.target.value));
	}

	// functions
	const refresh = () => {
		setPersons([{ name: "" }, { name: "" }, { name: "" }, { name: "" }]);
		setItems([{ name: "", price: "", notPay: [] }, { name: "", price: "", notPay: [] }, { name: "", price: "", notPay: [] },
		{ name: "", price: "", notPay: [] }, { name: "", price: "", notPay: [] }]);
		setTax(0);
	}

	const screenshot = () => {
		// Use html2canvas to capture the table as an image
		html2canvas(table.current).then((canvas) => {
			// Create a temporary <a> element to hold the image data
			const a = document.createElement('a');
			a.href = canvas.toDataURL('image/png');
			a.download = 'table.png';

			// Trigger a click event on the <a> element to initiate the download
			a.click();
		});
	}

	return (
		<Grid container
			direction="column"
			justifyContent="flex-start"
			style={{ minHeight: '60vh' }}
			spacing={1}
			ref={table}
			columns={{
				lg: 12,
				md: 8,
				xs: 4,
			}}
		>
			{/*first line*/}
			<Grid container justifyContent="center" spacing={1}>
				{/*tab*/}
				<StyledGrid>
					<UnclikableButton variant='outlined' disabled> Name </UnclikableButton>
				</StyledGrid>
				<StyledGrid>
					<UnclikableButton variant='outlined' disabled> Price </UnclikableButton>
				</StyledGrid>
				{persons.map((person, index) => <StyledGrid key={`person-${index}`}>
					<NameContainer focused color='secondary' placeholder={`User ${index}`} value={person.name} onChange={modifyPersonName(index)}
						InputProps={{
							startAdornment: <AccountCircleOutlinedIcon color='secondary' sx={{ display: { xs: 'none', lg:'block' } }} />,
							endAdornment: <IconButton color='error' onClick={clickRemovePerson(index)}><Cancel /></IconButton>
						}}>
					</NameContainer>
				</StyledGrid>
				)}
				<StyledGrid>
					<Button variant="contained" color="secondary" onClick={clickAddPerson()} sx={{ height: "100%" }} >
						<AddIcon />
					</Button>
				</StyledGrid>
			</Grid>
			{/*rows*/}
			{items.map((item, index) => <Grid container item key={`item-${index}`} justifyContent="center" spacing={1}>
				{/*item self*/}
				<StyledGrid>
					<NameContainer focused color='secondary' placeholder={`Item ${index}`} value={item.name} onChange={modifyItemName(index)}
						InputProps={{ endAdornment: <IconButton color='error' onClick={clickRemoveItem(index)}><Cancel /></IconButton> }}>
					</NameContainer>
				</StyledGrid>
				<StyledGrid>
					<NameContainer focused color='secondary' label="price" placeholder={"0"} type="number" value={item.price} onChange={modifyItemPrice(index)}
						InputProps={{ startAdornment: <AttachMoneyIcon color='secondary' /> }}>
					</NameContainer>
				</StyledGrid>
				{persons.map((p, pindex) => <StyledGrid key={`value-${index}-${pindex}`}>
					<ClickableButton variant={item.notPay.includes(pindex) ? "outlined" : "contained"} color='secondary'
						onClick={clickPrice(pindex, index)}>
						{round(values?.[index]?.[pindex]).toString()}
					</ClickableButton>
				</StyledGrid>
				)}
				<StyledGrid>
				</StyledGrid>
			</Grid>)}
			<Grid container item justifyContent="center" spacing={1}>
				{/*tab*/}
				<StyledGrid>
					<Button variant="contained" color="secondary" onClick={clickAddItem()} sx={{ height: "100%" }}>
						<AddIcon />
					</Button>
				</StyledGrid>
				<StyledGrid>
					<Typography
						align="center"
						sx={{ fontSize: "2rem", }}
						color="primary"
					>
						Total:
					</Typography>
				</StyledGrid>
				{total.map((totalPerPerson, index) => <StyledGrid key={`totalPerPerson-${index}`}>
					<UnclikableButton disabled variant='contained' color='primary'>
						<AttachMoneyIcon /> {round(totalPerPerson).toString()}
					</UnclikableButton>
				</StyledGrid>
				)}
				<StyledGrid>
					<NameContainer focused color='secondary' label="tax" placeholder={"0"} type="number" value={tax ? tax.toString() : ""} onChange={modifyTax()}
						InputProps={{ endAdornment: <InputAdornmentStyled> % </InputAdornmentStyled> }}>
					</NameContainer>
				</StyledGrid>
			</Grid>
		</Grid>
	)
});

export default Caculator;
