import React, { useRef } from 'react';

import Grid from '@mui/material/Unstable_Grid2';
import { Divider, Typography, Box, AppBar, Link, Toolbar, IconButton, SpeedDial, SpeedDialIcon, SpeedDialAction } from '@mui/material';

import { styled } from '@mui/material/styles';

import RefreshIcon from '@mui/icons-material/Refresh';
import PrintIcon from '@mui/icons-material/Print';

import Caculator from './Caculator';

import html2canvas from 'html2canvas';

const BillSpliter = (props) => {

    const table = useRef(null);


    const clearTable = () => {
        if (table) {
            table.current.refresh();
        }
    }

    const print = () => {
        if (table) {
            table.current.screenshot();
        }
    }

    const actions = [
        { icon: <RefreshIcon />, name: 'Refresh', operation: clearTable },
        { icon: <PrintIcon />, name: 'Print', operation: print },
    ];



    return (
        <>
            <AppBar>
                <Toolbar>
                    <Box component='img' src='/images/label.svg'></Box>

                    <Typography
                        align="right"
                        sx={{ fontSize: "20px", fontFamily: 'sans-serif', margin: '20px', flexGrow: 1 }}
                    >
                        <Link
                            underline="none"
                            href='http://robinseasky.org'
                            color='primary.contrastText'
                            target="_blank"
                            rel="noopener"

                        >
                            About Author
                        </Link>
                    </Typography>

                </Toolbar>
            </AppBar>
            <Box sx={{ position: 'sticky', top: '10vh', left: 0, flexGrow: 1, zIndex:10 }}>
                <SpeedDial
                    ariaLabel="Caculator Tool"
                    sx={{ position: 'absolute', left: '15px', top: 0 }}
                    icon={<SpeedDialIcon />}
                    direction='down'
                >
                    {actions.map((action) => (
                        <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            onClick={action.operation}
                        />
                    ))}
                </SpeedDial>
            </Box>
            <Grid container
                direction="column"
                justifyContent="flex-start"
                alignItems='center'
                spacing={3}
                sx={{
                    marginTop: '80px',
                }}
            >
                <Grid>
                    <Typography
                        align="center"
                        sx={{ fontSize: "40px", fontFamily: 'sans-serif' }}
                        color="secondary"
                    >
                        Welcome to Bill <Box component='img' sx={{
                            width: '80px',
                            position: 'relative',
                            top: '20px',
                        }}  src="/images/bill-split.svg" alt="bill split" /> Split Caculator!
                    </Typography>
                </Grid>
                <Grid>
                    <Typography
                        align="center"
                        sx={{ fontSize: "36px", fontFamily: 'serif' }}
                        color="primary"
                    >
                        split your bills faster than ever
                    </Typography>
                </Grid>
                <Grid>
                    <Box sx={{
                        width: '95vw',
                        height: '1px',
                        background: 'black',
                    }}></Box>
                </Grid>
                <Grid>
                    <Caculator ref={table} />
                </Grid>
            </Grid>

        </>
    )

}

export default BillSpliter;