import { ThemeProvider, createTheme } from '@mui/material/styles';

import BillSpliter from './BillSpliter';


import './App.css';

const theme = createTheme({
  palette: {
    primary: {
          main: '#5C3091',
          contrastText: '#fff',
    },
    secondary: {
        main: '#E68800',
        contrastText: '#fff',
    },
  },
});


const App = (props) => {
    return (
        <ThemeProvider theme={theme}>
            <BillSpliter />
          </ThemeProvider>
    )
}

export default App;